import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { css } from "@emotion/react"
import { rgba } from "emotion-rgba"
import { colors as theme, units } from "./variables"
import useTheme from "../hooks/useTheme"

const NavigationLink = ({ to, ariaLabel, children, primary }) => {
  const isDarkTheme = useTheme()
  const colors = isDarkTheme ? theme.dark : theme.light

  const styles = {
    root: css({
      alignContent: "center",
      borderRadius: units.radius.medium,
      boxSizing: "border-box",
      color: colors.grey1,
      display: "flex",
      fill: colors.grey1,
      font: "inherit",
      justifyContent: "center",
      lineHeight: "1",
      outline: "none",
      padding: units.spacer.small,
      textDecoration: "none",
      transitionDuration: units.speed.fast,
      whiteSpace: "nowrap",
      width: "100%",

      "&:hover": {
        color: colors.primary,
        fill: colors.primary,
      },

      "&:focus": {
        color: colors.primary,
        backgroundColor: rgba(colors.primary, 0.16),
        fill: colors.primary,
      },
    }),
    active: css({
      color: colors.grey1,
      fontWeight: "600",
      pointerEvents: "none",
    }),
    primary: css({
      fontWeight: "600",
    }),
  }

  const handleClick = (event) => {
    event.target.blur()
    event.target.parentNode.blur()

    if (window && window.gtag) {
      window.gtag("event", "click", {
        event_category: "Nav Button",
        event_label: event.target.innerText,
      })
    }
  }

  return (
    <Link
      css={[styles.root, primary === true ? styles.primary : undefined]}
      activeClassName={styles.active}
      to={to}
      onClick={handleClick}
      aria-label={ariaLabel}>
      {children}
    </Link>
  )
}

NavigationLink.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  primary: PropTypes.bool,
}

NavigationLink.defaultProps = {
  primary: false,
}

const OutboundNavigationLink = ({ to, target, ariaLabel, children }) => {
  const isDarkTheme = useTheme()
  const colors = isDarkTheme ? theme.dark : theme.light

  const styles = {
    root: css({
      alignContent: "center",
      borderRadius: units.radius.medium,
      boxSizing: "border-box",
      color: colors.grey1,
      display: "flex",
      fill: colors.grey1,
      font: "inherit",
      justifyContent: "center",
      lineHeight: "1",
      outline: "none",
      padding: units.spacer.small,
      textDecoration: "none",
      transitionDuration: units.speed.fast,
      whiteSpace: "nowrap",
      width: "100%",

      "&:hover": {
        color: colors.primary,
        fill: colors.primary,
      },

      "&:focus": {
        color: colors.primary,
        backgroundColor: rgba(colors.primary, 0.16),
        fill: colors.primary,
      },
    }),
  }

  return (
    <OutboundLink
      css={styles.root}
      href={to}
      target={target}
      aria-label={ariaLabel}>
      {children}
    </OutboundLink>
  )
}

OutboundNavigationLink.defaultProps = {
  target: "_blank",
}

OutboundNavigationLink.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string,
  target: PropTypes.oneOf(["_self", "_blank", "_parent", "_top"]),
  ariaLabel: PropTypes.string,
}

export { NavigationLink, OutboundNavigationLink }
export default NavigationLink
