import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Footer from "../components/Footer"
import Navigation from "../components/Navigation"
import { Global, css } from "@emotion/react"
import { colors as theme, typography, units } from "../components/variables"
import useTheme from "../hooks/useTheme"

const Layout = ({ children, location }) => {
  const isDarkTheme = useTheme()
  const colors = isDarkTheme ? theme.dark : theme.light

  const styles = css({
    padding: "4vw",
  })

  return (
    <Fragment>
      <Global
        styles={css`
          @import "https://fonts.googleapis.com/icon?family=Material+Icons&display=swap";

          @font-face {
            font-display: swap;
            font-family: "Scto Grotesk A";
            font-weight: 400;
            font-style: normal;
            src: url("/fonts/Scto Grotesk A Regular.woff") format("woff");
          }

          @font-face {
            font-display: swap;
            font-family: "Scto Grotesk A";
            font-weight: 400;
            font-style: oblique;
            src: url("/fonts/Scto Grotesk A Regular Italic.woff") format("woff");
          }

          @font-face {
            font-display: swap;
            font-family: "Scto Grotesk A";
            font-weight: 500;
            font-style: normal;
            src: url("/fonts/Scto Grotesk A Medium.woff") format("woff");
          }

          @font-face {
            font-display: swap;
            font-family: "Scto Grotesk A";
            font-weight: 500;
            font-style: oblique;
            src: url("/fonts/Scto Grotesk A Medium Italic.woff") format("woff");
          }

          @font-face {
            font-display: swap;
            font-family: "Scto Grotesk A";
            font-weight: 700;
            font-style: normal;
            src: url("/fonts/Scto Grotesk A Bold.woff") format("woff");
          }

          @font-face {
            font-display: swap;
            font-family: "Scto Grotesk A";
            font-weight: 700;
            font-style: oblique;
            src: url("/fonts/Scto Grotesk A Bold Italic.woff") format("woff");
          }

          html {
            font-size: 70.3%;
            overflow-x: hidden;
            scroll-behavior: smooth;
            width: 100%;

            @media (min-width: ${units.breakpoint.medium}) {
              font-size: 62.5%;
            }
          }

          body {
            background-color: ${colors.background};
            color: ${colors.grey1};
            fill: ${colors.grey1};
            font: ${typography.body};
            margin: 0;
            overflow-x: hidden;
            padding-top: ${units.spacer.xLarge};
            width: 100%;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @media (prefers-color-scheme: dark) {
              background-color: ${theme.dark.background};
            }
          }

          ::selection {
            background-color: ${colors.grey1};
            color: ${colors.grey6};
          }

          img,
          svg {
            display: block;
          }

          img {
            max-width: 100%;
          }
        `}
      />

      <Navigation />
      <main css={styles}>{children}</main>
      <Footer />
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
}

export default Layout
