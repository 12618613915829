import React from "react"
import PropTypes from "prop-types"

const SVG = ({ size, color, children, ...props }) => (
  <svg {...props} viewBox="0 0 24 24" height={size} width={size} fill={color}>
    {children}
  </svg>
)

SVG.defaultProps = {
  size: "24",
  color: "inherit",
}

SVG.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
}

const Dribbble = (props) => (
  // eslint-disable-next-line
  <SVG {...props}>
    <path d="m12 .00000677c-6.62742004 0-12 5.37258676-12 12.00000003 0 6.6274132 5.37257996 12 12 12 6.6274065 0 12-5.3725868 12-12 .0033744-3.18363352-1.2598189-6.23784765-3.510989-8.48901777-2.2511701-2.25117011-5.3053843-3.51436347-8.489011-3.51098226zm7.8749888 5.81250351c1.3214258 1.69910626 2.0698158 3.77359073 2.1374988 5.92499662-2.3112086-.4483831-4.6806293-.5117366-7.0124961-.1874999-.2624998-.5999996-.5249997-1.1624993-.7874995-1.68749901 2.1859011-.87467057 4.1284003-2.26400772 5.6624968-4.04999771zm-7.8749956-3.82499784c2.3811054-.00820062 4.6850129.84411197 6.4874963 2.39999865-1.3959921 1.65795157-3.1892847 2.93518879-5.212497 3.7124979-1.080787-2.02887602-2.2959956-3.98323213-3.63749795-5.84999669.77378145-.18389977 1.56722175-.27205981 2.36249865-.26249986zm-4.31249755.97499945c1.35840722 1.83149645 2.59843145 3.74789749 3.71249795 5.73749676-2.81249845.78749955-5.96249667.97499945-9.14999487.97499945.70694375-2.94327048 2.70509806-5.40995753 5.43749692-6.71249621zm-5.69999677 9.03749571v-.3375006h.03749998c3.56249798 0 7.12499597-.2249999 10.31249414-1.1624994.2249999.4874998.4874997.9749995.7124996 1.5-4.1999976 1.2374985-6.78749614 3.9749969-8.58749512 6.5999955-1.59537766-1.8286744-2.47457949-4.1732126-2.4749986-6.5999955zm10.01249432 10.0124984c-2.19585071-.0059176-4.32943563-.7302828-6.07499655-2.0625037 1.68749905-2.5499986 4.04999771-5.0624971 7.87499555-6.1124966.9428051 2.3940605 1.6218156 4.8837658 2.0249989 7.4249958-1.2140452.4965278-2.513341.7512917-3.8249979.7500045zm5.6624968-1.7625039c-.41577-2.32737-1.04338-4.6118704-1.8749989-6.8249961 2.0384393-.2395886 4.1021025-.1509651 6.1124965.2624998-.4781213 2.6661338-2.0042797 5.0296535-4.2374976 6.5624963z" />
  </SVG>
)

const Twitter = (props) => (
  // eslint-disable-next-line
  <SVG {...props}>
    <path d="m24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
  </SVG>
)

const Instagram = (props) => (
  // eslint-disable-next-line
  <SVG {...props}>
    <path d="m12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948s.014 3.668.072 4.948c.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072s3.668-.014 4.948-.072c4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948s-.014-3.667-.072-4.947c-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
  </SVG>
)
const LinkedIn = (props) => (
  // eslint-disable-next-line
  <SVG {...props}>
    <path d="m19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476z" />
  </SVG>
)

const Logo = (props) => (
  // eslint-disable-next-line
  <SVG {...props}>
    <path
      d="M180,192a12,12,0,1,1,12-12A12,12,0,0,1,180,192Zm.27-16.53a1.54,1.54,0,0,0-2.22,0h0l-5.91,6.08h0a1.66,1.66,0,0,0,0,2.29,1.54,1.54,0,0,0,2.22,0h0l5.92-6.08h0A1.64,1.64,0,0,0,180.27,175.47Zm6.27,0a1.55,1.55,0,0,0-2.23,0l-.12.16h0l-5.78,5.94h0a1.66,1.66,0,0,0,0,2.29,1.55,1.55,0,0,0,2.23,0h0l5.78-5.95h0l.16-.13A1.66,1.66,0,0,0,186.54,175.47Zm0,6.1a1.55,1.55,0,0,0-2.23,0,1.66,1.66,0,0,0,0,2.29,1.55,1.55,0,0,0,2.23,0A1.66,1.66,0,0,0,186.54,181.57Z"
      transform="translate(-168 -168)"
    />
  </SVG>
)

export { Dribbble, Instagram, LinkedIn, Logo, Twitter }

export default SVG
